import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db, rtdb } from "../firebase";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp as firestoreTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  limit,
  setLogLevel,
  writeBatch,
  orderBy,
} from "firebase/firestore";
import {
  onValue,
  push,
  ref,
  update,
  onChildAdded,
  serverTimestamp as rtdbTimestamp,
  remove,
  set,
  get,
  orderByChild,
  limitToFirst,
  equalTo,
  child,
} from "firebase/database";
import ReactLoading from "react-loading";
import { FaFacebook } from "react-icons/fa";

setLogLevel("silent");

const ChatSession = () => {
  const chatContainerRef = useRef(null);
  const textInputRef = useRef(null);
  const failCountRef = useRef(0);
  const { user, setTheCurrentPage, displayName, university } = UserAuth();
  const [chatSessionID, setChatSessionID] = useState(null);
  const [collectionName, setCollectionName] = useState(null);
  const [chatSessionStarted, setChatSessionStarted] = useState(false);
  const [chatSessionEnded, setChatSessionEnded] = useState(false);
  const [chatSessionForcelyEnded, setChatSessionForcelyEnded] = useState(false);
  const [showAreYouSure, setShowAreYouSure] = useState(false);
  const [userType, setUserType] = useState(null);
  const [poster, setPoster] = useState(null);
  const [isFetchingPoster, setIsFetchingPoster] = useState(false);

  // message
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  // size handling
  const [isMobile, setIsMobile] = useState(false);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [mobileHeight, setMobileHeight] = useState("86vh");

  // FOR STRANGER INFO USESTATES
  const [isStrangerTyping, setIsStrangerTyping] = useState(false);
  const [strangerDisplayName, setStrangerDisplayName] = useState(null);
  const [strangerUniversity, setStrangerUniversity] = useState(
    "I prefer not to disclose"
  );
  const [strangerUserID, setStrangerUserID] = useState(null);

  const [temporaryID, setTemporaryID] = useState(null);
  const [rerunKey, setRerunKey] = useState(0);
  const [searchStatus, setSearchStatus] = useState("");

  const resetAllStates = () => {
    setChatSessionID(null);
    setChatSessionStarted(false);
    setChatSessionEnded(false);
    setChatSessionForcelyEnded(false);
    setShowAreYouSure(false);
    setUserType(null);
    setInputMessage("");
    setMessages([]);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (inputMessage.trim() === "") {
        e.preventDefault();
      } else {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  const sendMessage = async () => {
    try {
      if (showAreYouSure) {
        setShowAreYouSure(false);
      }
      if (isMobile) {
        const contentEditableDiv = document.getElementById(
          "yourContentEditableId"
        );
        if (contentEditableDiv) {
          contentEditableDiv.textContent = "";
        }
      }

      const currentDate = new Date();
      // Add 8 hours to the current date
      currentDate.setHours(currentDate.getHours() + 8);
      // Convert the updated date to ISO string
      const updatedDateISOString = currentDate.toISOString();

      const newMessage = {
        text: inputMessage,
        sender: user.uid,
        timestamp: updatedDateISOString,
      };
      setInputMessage("");

      const messageRef = ref(rtdb, `MessagesV5/${chatSessionID}/messages`);

      await push(messageRef, newMessage);
    } catch (error) {
      console.log("Error");
    }
  };

  const endChat = async () => {
    try {
      const chatSessionRef = doc(db, collectionName, chatSessionID);
      setChatSessionID(null);
      setChatSessionForcelyEnded(true);
      await updateDoc(chatSessionRef, { ended: true });
    } catch (error) {
      console.log("Error");
    }
  };

  const getRandomCollection = () => {
    const collections = [
      "ChatSessionRandom1",
      "ChatSessionRandom2",
      "ChatSessionRandom3",
    ];
    // Generate a random number between 0 and 2 inclusive
    const randomIndex = Math.floor(Math.random() * collections.length);
    const selectedCollection = collections[randomIndex];

    console.log("Selected collection:", selectedCollection); // Debug log
    return selectedCollection;
  };

  // useEffect(() => {
  //   const fetchPoster = async () => {
  //     const q = query(
  //       collection(db, "Posters"),
  //       where("status", "==", "Displaying")
  //     );
  //     const querySnapshot = await getDocs(q);

  //     if (!querySnapshot.empty) {
  //       setPoster(querySnapshot.docs[0].data());
  //       setIsFetchingPoster(false);
  //     } else {
  //       setPoster(null);
  //       setIsFetchingPoster(false);
  //     }
  //   };

  //   fetchPoster();
  // }, []);

  useEffect(() => {
    if (isFetchingPoster === false) {
      try {
        let tempID = null;
        runTransaction(db, async (transaction) => {
          const selectedCollectionName = getRandomCollection();
          setCollectionName(selectedCollectionName);
          if (failCountRef.current > 3) {
            const chatSessionsCollection = collection(
              db,
              selectedCollectionName
            );
            const chatSessionRef = doc(chatSessionsCollection);
            // console.log("LIMIT EXCEED. FORCELY CREATES DOC");
            await setDoc(chatSessionRef, {
              timestamp: firestoreTimestamp(),
              ended: false,
              creatorUserID: user.uid,
              creatorDisplayName: displayName,
              creatorUniversity: university,
              joinerUserID: null,
              joinerDisplayName: null,
              joinerUniversity: null,
            });
            setUserType("creator");
            tempID = chatSessionRef.id;
            // console.log("doc created successfully");
          } else {
            // console.log("transaction starts");
            // console.log("failCount: " + failCountRef.current);
            failCountRef.current++;
            const chatSessionsCollection = collection(
              db,
              selectedCollectionName
            );
            const querySnapshot = await getDocs(
              query(
                chatSessionsCollection,
                where("creatorUserID", "!=", user.uid),
                where("joinerUserID", "==", null),
                limit(1)
              )
            );
            if (querySnapshot.empty) {
              // console.log("empty doc");
              const chatSessionRef = doc(chatSessionsCollection);
              // console.log("creates own chatsession");
              transaction.set(chatSessionRef, {
                timestamp: firestoreTimestamp(),
                ended: false,
                creatorUserID: user.uid,
                creatorDisplayName: displayName,
                creatorUniversity: university,
                joinerUserID: null,
                joinerDisplayName: null,
                joinerUniversity: null,
              });
              setUserType("creator");
              tempID = chatSessionRef.id;
            } else {
              // console.log(querySnapshot.docs.length);
              // console.log("found one");
              const foundDocument = querySnapshot.docs[0];
              const foundDocumentRef = doc(
                db,
                selectedCollectionName,
                foundDocument.id
              );
              const sessionData = (
                await transaction.get(foundDocumentRef)
              ).data();
              // console.log("got document data");
              if (sessionData.joinerUserID === null) {
                // console.log("tries to update");
                transaction.update(foundDocumentRef, {
                  joinerUserID: user.uid,
                  joinerDisplayName: displayName,
                  joinerUniversity: university,
                });
                setUserType("joiner");
                // console.log("update finished");
                tempID = foundDocument.id;
                // console.log("TEMPID: " + tempID);
              } else {
                // problem is here
                // console.log("Session already has a joiner, transaction aborted");
                throw new Error("Error throwed");
              }
            }
          }
        })
          .then(() => {
            if (tempID === null) {
              // console.log("no tempID, will rerun");
              triggerRerun();
            } else {
              // console.log("transaction finish");
              failCountRef.current = 0;
              setChatSessionID(tempID);
            }
          })
          .catch(async (error) => {
            console.error("Failed:", error, ". Function will now rerun");
            triggerRerun();
          });
      } catch (error) {
        console.log("Error", error);
      }
    }
  }, [rerunKey, isFetchingPoster]);

  const triggerRerun = () => {
    setRerunKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (chatSessionID) {
      const chatSessionRef = doc(db, collectionName, chatSessionID);
      const messagesRef = ref(
        rtdb,
        "MessagesV5/" + chatSessionRef.id + "/messages"
      );

      const chatSessionUnsubscribe = onSnapshot(
        chatSessionRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.creatorUserID && data.joinerUserID) {
              setChatSessionStarted(true);
            }
            if (data.ended && !chatSessionForcelyEnded && chatSessionID) {
              setChatSessionEnded(true);
            }
            if (userType === "joiner") {
              setStrangerDisplayName(data.creatorDisplayName);
              setStrangerUniversity(data.creatorUniversity);
              setStrangerUserID(data.creatorUserID);
            } else if (userType === "creator") {
              setStrangerDisplayName(data.joinerDisplayName);
              setStrangerUniversity(data.joinerUniversity);
              setStrangerUserID(data.joinerUserID);
            }
          }
        }
      );

      // rtdb message listener
      const messageUnsubscribe = onChildAdded(messagesRef, (snapshot) => {
        const newMessage = snapshot.val();
        // try
        if (newMessage.sender !== user.uid) {
          setIsStrangerTyping(false);
        }
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: newMessage.sender || "",
            text: newMessage.text || "",
            timestamp: newMessage.timestamp || "",
          },
        ]);
      });

      return () => {
        if (chatSessionUnsubscribe || messageUnsubscribe) {
          chatSessionUnsubscribe();
          messageUnsubscribe();
        }
      };
    }
  }, [chatSessionID]);

  // HANDLES USER TYPING
  useEffect(() => {
    if (
      strangerUserID &&
      chatSessionStarted &&
      !chatSessionEnded &&
      !chatSessionForcelyEnded
    ) {
      const userTypingRef = ref(rtdb, "UserTypingV5/" + strangerUserID);
      const rtdbIsTypingUnsubscribe = onValue(userTypingRef, (snapshot) => {
        setIsStrangerTyping(snapshot.val().isTyping);
      });

      return () => {
        if (rtdbIsTypingUnsubscribe) {
          rtdbIsTypingUnsubscribe();
        }
      };
    }
  }, [
    strangerUserID,
    chatSessionStarted,
    chatSessionEnded,
    chatSessionForcelyEnded,
  ]);

  // serves as a ploy so that the rtdb doesnt get updated everytime the user types a character, it only updates based on if there is input message or none
  useEffect(() => {
    if (inputMessage) {
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }
  }, [inputMessage]);

  // updates own user's usertyping state on rtdb
  useEffect(() => {
    const userTypingRef = ref(rtdb, "UserTypingV5/" + user.uid);
    if (isTyping) {
      update(userTypingRef, { isTyping: true, timestamp: rtdbTimestamp() });
    } else {
      update(userTypingRef, { isTyping: false, timestamp: rtdbTimestamp() });
    }
  }, [isTyping]);

  // MANAGES MOBILE VIEW
  // this causes flickering
  // useEffect(() => {
  //   if (isStrangerTyping) {
  //     if (isMobile) {
  //       setMobileHeight("80vh");
  //     }
  //   } else {
  //     if (isMobile) {
  //       setMobileHeight("86vh");
  //     }
  //   }
  // }, [isStrangerTyping]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        if (showAreYouSure && !chatSessionForcelyEnded) {
          endChat();
        } else {
          if (!chatSessionForcelyEnded) {
            setShowAreYouSure(true);
          } else {
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showAreYouSure, chatSessionForcelyEnded]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [
    messages,
    chatSessionEnded,
    chatSessionForcelyEnded,
    mobileHeight,
    isStrangerTyping,
  ]);

  // officially working
  // to prevent back button
  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  return (
    <>
      {chatSessionStarted ? (
        // mobile
        isMobile ? (
          <div style={{ backgroundColor: "#fcf0f8" }}>
            <div
              style={{
                overflowY: "scroll",
                paddingLeft: "20px",
                padding: "20px",
                height: mobileHeight,
              }}
              ref={chatContainerRef}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <img
                  src={require("../mainlogo.png")}
                  alt="Logo"
                  style={{
                    borderRadius: "100%",
                    height: "25px",
                    marginRight: "10px",
                  }}
                />
                <p style={{ color: "#004AAD", fontSize: 15 }}>
                  <strong>ChatKOOL</strong>
                </p>
              </div>
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "15px",
                    textAlign: "left",
                    margin: 0,
                    padding: 0,
                    fontWeight: "300",
                  }}
                >
                  You are now chatting with{" "}
                  {strangerDisplayName === null ? "a" : null}
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  {strangerUniversity === "I prefer not to disclose" ||
                  strangerUniversity === null ? null : (
                    <img
                      style={{ height: 50 }}
                      src={require(`../assets/universities/${strangerUniversity}.png`)}
                      alt={strangerUniversity}
                    />
                  )}
                  {strangerDisplayName ? (
                    <p
                      style={{
                        fontSize: "20px",
                        margin: 0,
                        padding: 0,
                        marginLeft: 10,
                      }}
                    >
                      {strangerDisplayName}
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#004AAD",
                        fontWeight: "500",
                        margin: 0,
                        padding: 0,
                        marginLeft: 10,
                      }}
                    >
                      Stranger
                    </p>
                  )}
                </div>
              </div>
              <ul
                style={{ listStyleType: "none", padding: 0, paddingBottom: 20 }}
              >
                {messages.map((message) => (
                  <li
                    key={message.timestamp}
                    style={{
                      textAlign: message.sender === user.uid ? "right" : "left",
                      padding: "4px",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor:
                          message.sender === user.uid ? "#004AAD" : "lightgray",
                        color: message.sender === user.uid ? "white" : "black",
                        borderRadius: "8px",
                        padding: "8px",
                        display: "inline-block",
                        maxWidth: "70%",
                        wordWrap: "break-word",
                        textAlign: "left",
                        marginLeft: message.sender === user.uid ? "auto" : "0",
                      }}
                    >
                      {message.text}
                    </span>
                  </li>
                ))}
                {isStrangerTyping ? (
                  <div
                    style={{
                      backgroundColor: "lightgray",
                      display: "inline-block",
                      borderRadius: 8,
                      paddingRight: 5,
                      paddingLeft: 5,
                      marginLeft: 5,
                      marginTop: 2,
                    }}
                  >
                    <ReactLoading
                      type="bubbles"
                      color="#808080"
                      height={33}
                      width={33}
                    />
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {chatSessionEnded && chatSessionID ? (
                    <p
                      style={{
                        fontSize: "15px",
                        color: "#FF66C4",
                        textAlign: "center",
                        fontWeight: "500",
                      }}
                    >
                      {strangerDisplayName ? strangerDisplayName : "Stranger"}{" "}
                      left the chat
                    </p>
                  ) : null}
                  {chatSessionForcelyEnded ? (
                    <p
                      style={{
                        fontSize: 15,
                        color: "#FF66C4",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <strong>You ended the chat</strong>
                    </p>
                  ) : null}
                </div>
              </ul>
            </div>
            <div
              style={{
                backgroundColor: "#fcf0f8",
                display: "flex",
                flexDirection: "column",
                position: "fixed",
                bottom: 0,
                marginLeft: "2.5%",
                paddingBottom: "10px",
                width: "95%",
              }}
            >
              <div style={{ display: "flex", alignItems: "end" }}>
                {chatSessionEnded || chatSessionForcelyEnded ? (
                  <>
                    <button
                      onClick={() => {
                        resetAllStates();
                        triggerRerun();
                      }}
                      style={{
                        marginRight: 5,
                        flex: 1,
                        height: "40px",
                        borderRadius: "8px",
                        backgroundColor: "#FF66C4",
                        color: "white",
                        borderWidth: 0,
                      }}
                    >
                      <strong>Okay, next</strong>
                    </button>
                    <button
                      onClick={() => {
                        setTheCurrentPage("Selection");
                      }}
                      style={{
                        marginLeft: 5,
                        flex: 1,
                        height: "40px",
                        borderRadius: "8px",
                        backgroundColor: "#004AAD",
                        color: "white",
                        borderWidth: 0,
                      }}
                    >
                      <strong>Main Menu</strong>
                    </button>
                  </>
                ) : (
                  <>
                    {showAreYouSure ? (
                      <button
                        onClick={endChat}
                        style={{
                          flex: 0.1,
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          marginRight: "5px",
                        }}
                      >
                        <strong>Sure?</strong>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setShowAreYouSure(true);
                        }}
                        style={{
                          flex: 0.1,
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          marginRight: "5px",
                          backgroundColor: "white",
                        }}
                      >
                        <strong>End</strong>
                      </button>
                    )}
                    <div
                      id="yourContentEditableId"
                      contentEditable
                      value={inputMessage}
                      onInput={(e) => {
                        if (showAreYouSure) {
                          setShowAreYouSure(false);
                        }
                        setInputMessage(e.target.textContent);
                      }}
                      onKeyDown={handleKeyPress}
                      style={{
                        flex: 0.7,
                        padding: "10px",
                        marginRight: "5px",
                        border: "1px solid #000",
                        borderRadius: "8px",
                        maxHeight: "50px",
                        overflowY: "auto",
                        backgroundColor: "white",
                      }}
                    />
                    <button
                      onClick={sendMessage}
                      disabled={!inputMessage.trim()}
                      style={{
                        flex: 0.2,
                        backgroundColor: "white",
                        height: "40px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <strong>Send</strong>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          // desktop/laptop
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "#c7c7c7",
            }}
          >
            <div
              style={{
                width: "35%",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                justifyContent: "space-between",
                backgroundColor: "#fcf0f8",
              }}
            >
              <div
                style={{
                  flex: 1,
                  overflowY: "scroll",
                  paddingTop: "20px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
                ref={chatContainerRef}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    marginLeft: 20,
                  }}
                >
                  <img
                    src={require("../mainlogo.png")}
                    alt="Logo"
                    style={{
                      borderRadius: "100%",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  />
                  <p style={{ color: "#004AAD", fontSize: 15 }}>
                    <strong>ChatKOOL</strong>
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      textAlign: "left",
                      margin: 0,
                      padding: 0,
                      fontWeight: "300",
                    }}
                  >
                    You are now chatting with{" "}
                    {strangerDisplayName === null ? "a" : null}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    {strangerUniversity === "I prefer not to disclose" ||
                    strangerUniversity === null ? null : (
                      <img
                        style={{ height: 50 }}
                        src={require(`../assets/universities/${strangerUniversity}.png`)}
                        alt={strangerUniversity}
                      />
                    )}
                    {strangerDisplayName ? (
                      <p
                        style={{
                          fontSize: "20px",
                          margin: 0,
                          padding: 0,
                          marginLeft: 10,
                        }}
                      >
                        {strangerDisplayName}
                      </p>
                    ) : (
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "#004AAD",
                          margin: 0,
                          padding: 0,
                          marginLeft: 10,
                        }}
                      >
                        Stranger
                      </p>
                    )}
                  </div>
                </div>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {messages.map((message) => (
                    <li
                      key={message.timestamp}
                      style={{
                        textAlign:
                          message.sender === user.uid ? "right" : "left",
                        padding: "5px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor:
                            message.sender === user.uid
                              ? "#004AAD"
                              : "lightgray",
                          color:
                            message.sender === user.uid ? "white" : "black",
                          borderRadius: "8px",
                          padding: "8px",
                          display: "inline-block",
                          textAlign: "left",
                          maxWidth: "70%", // You can adjust the percentage as needed
                          wordWrap: "break-word", // Ensure long words don't break layout
                        }}
                      >
                        {message.text}
                      </span>
                    </li>
                  ))}
                  {isStrangerTyping ? (
                    <div
                      style={{
                        backgroundColor: "lightgray",
                        display: "inline-block",
                        borderRadius: 8,
                        paddingRight: 5,
                        paddingLeft: 5,
                        marginLeft: 5,
                        marginTop: 2,
                      }}
                    >
                      <ReactLoading
                        type="bubbles"
                        color="#808080"
                        height={33}
                        width={33}
                      />
                    </div>
                  ) : null}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {chatSessionEnded && chatSessionID ? (
                      <p
                        style={{
                          fontSize: 15,
                          color: "#FF66C4",
                          margin: 0,
                          paddingBottom: 10,
                          fontWeight: "500",
                        }}
                      >
                        {strangerDisplayName ? strangerDisplayName : "Stranger"}{" "}
                        left the chat
                      </p>
                    ) : null}
                    {chatSessionForcelyEnded ? (
                      <p
                        style={{
                          fontSize: 14,
                          color: "#FF66C4",
                          margin: 0,
                          paddingBottom: 10,
                        }}
                      >
                        <strong>You ended the chat</strong>
                      </p>
                    ) : null}
                  </div>
                </ul>
              </div>
              {chatSessionEnded || chatSessionForcelyEnded ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "10px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <>
                      <button
                        onClick={() => {
                          resetAllStates();
                          triggerRerun();
                        }}
                        style={{
                          marginRight: 5,
                          flex: 1,
                          height: "40px",
                          borderRadius: "8px",
                          backgroundColor: "#FF66C4",
                          color: "white",
                          borderWidth: 0,
                        }}
                      >
                        <strong>Okay, next</strong>
                      </button>
                      <button
                        onClick={() => {
                          setTheCurrentPage("MainMenu");
                        }}
                        style={{
                          marginLeft: 5,
                          flex: 1,
                          height: "40px",
                          borderRadius: "8px",
                          backgroundColor: "#004AAD",
                          color: "white",
                          borderWidth: 0,
                        }}
                      >
                        <strong>Main Menu</strong>
                      </button>
                    </>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // margin: "10px",
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {showAreYouSure ? (
                      <button
                        onClick={endChat}
                        style={{
                          flex: 0.1,
                          height: "40px",
                          border: "1px solid #ccc",
                          marginRight: "5px",
                          borderRadius: 8,
                        }}
                      >
                        <strong>Sure?</strong>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setShowAreYouSure(true);
                        }}
                        style={{
                          flex: 0.1,
                          height: "40px",
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                          marginRight: "5px",
                          borderRadius: 8,
                        }}
                      >
                        <strong>End</strong>
                      </button>
                    )}
                    <input
                      ref={textInputRef}
                      type="text"
                      value={inputMessage}
                      onChange={(e) => {
                        if (showAreYouSure) {
                          setShowAreYouSure(false);
                        }
                        setInputMessage(e.target.value);
                      }}
                      onKeyDown={handleKeyPress}
                      placeholder="Say something"
                      style={{
                        flex: 0.7,
                        padding: "10px",
                        fontSize: "15px",
                        borderRadius: 8,
                      }}
                    />
                    <button
                      onClick={sendMessage}
                      disabled={!inputMessage.trim()}
                      style={{
                        flex: 0.2,
                        height: "40px",
                        marginLeft: "5px",
                        borderRadius: 8,
                      }}
                    >
                      <strong>Send</strong>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      ) : (
        <div
          style={{
            display: "flex",
            // border: "1px solid black",
            height: isMobile ? mobileHeight : "90vh",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            margin: 20,
          }}
        >
          {/* NON-POSTER DISPLAY */}
          <div
            style={{
              // border: "1px solid black",
              display: "flex",
              flex: 0.1,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactLoading type="bars" color="#FF66C4" />
            </div>
            <p
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              Finding a stranger...
            </p>
            <p
              style={{
                fontSize: 10,
                color: "gray",
                marginTop: 5,
                marginBottom: 0,
                fontWeight: "400",
              }}
            >
              Please be patient, this may take some time
            </p>
            <p
              style={{
                fontSize: 10,
                color: "gray",
                marginTop: 0,
                marginBottom: 0,
                fontWeight: "400",
              }}
            >
              If this takes over a minute, please try refreshing the page
            </p>
          </div>
          {/* HAS POSTER DISPLAY */}
          {/* {isFetchingPoster ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "90%",
                marginTop: 20,
                alignItems: "center",
                justifyContent: "center",
                maxWidth: 350,
                borderRadius: 20,
                marginBottom: 20,
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "gray",
                  fontSize: 13,
                  // fontWeight: "bold",
                }}
              >
                Loading ad...
              </p>
            </div>
          ) : poster ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "90%",
                marginTop: 20,
                alignItems: "center",
                justifyContent: "center",
                maxWidth: 350,
                borderRadius: 20,
                marginBottom: 20,
                flexDirection: "column",
              }}
            >
              <img
                src={poster.url} // Assuming poster has a 'url' field
                alt="User Poster"
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: 300, // Same as the div width for consistency
                  borderRadius: 20,
                }}
              />
              <div style={{ maxWidth: 300, marginTop: 10 }}>
                <p
                  style={{
                    fontSize: 13,
                    color: "gray",
                    fontWeight: "400",
                    marginTop: 0,
                    marginBottom: 5,
                  }}
                >
                  Want to share your poster too?
                </p>
              </div>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: 10,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                You may contact us through our official Facebook page
              </p>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 5 }}
              >
                <FaFacebook size={18} color={"blue"} />
                <p
                  style={{
                    margin: 0,
                    marginLeft: 5,
                    fontSize: 13,
                    // fontWeight: "bold",
                  }}
                >
                  @officialchatkool
                </p>
              </div>
            </div>
          ) : (
            <div
              style={{
                // border: "1px solid black",
                display: "flex",
                flex: 1,
                width: "90%",
                marginTop: 20,
                alignItems: "center",
                justifyContent: "center",
                maxWidth: 350,
                border: "2px dashed gray",
                borderRadius: 20,
                marginBottom: 20,
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontSize: 18,
                  color: "gray",
                  fontWeight: "300",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Place your ad, meme, or any poster here
              </p>
              <div style={{ maxWidth: 300 }}>
                <p
                  style={{
                    fontSize: 11,
                    color: "gray",
                    fontWeight: "300",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  Your poster will reach thousands of students nationwide
                </p>
              </div>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: 10,
                  marginTop: 20,
                  marginBottom: 0,
                }}
              >
                You may contact us through our official Facebook page
              </p>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 5 }}
              >
                <FaFacebook size={18} color={"blue"} />
                <p
                  style={{
                    margin: 0,
                    marginLeft: 5,
                    fontSize: 13,
                    // fontWeight: "bold",
                  }}
                >
                  @officialchatkool
                </p>
              </div>
            </div>
          )} */}
        </div>
      )}
    </>
  );
};

export default ChatSession;
