import { useContext, createContext, useEffect, useState } from "react";
import { signInAnonymously, signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isOver18, setIsOver18] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [displayName, setTheDisplayName] = useState("");
  const [university, setTheUniversity] = useState(null);
  const [hasSetIsTyping, setTheHasSetIsTyping] = useState(false);
  const [currentPage, setCurrentPage] = useState("Home");
  const [hasSuggested, setTheHasSuggested] = useState(false);
  const [createdChatroom, setTheCreatedChatroom] = useState(null);
  const [joinedChatRoomID, setTheJoinedChatRoomID] = useState(null);

  const anonymousSignIn = async () => {
    await signInAnonymously(auth);
  };

  const logOut = () => {
    signOut(auth);
    setUser(null);
  };

  const setOver18 = (value) => {
    setIsOver18(value);
  };

  const setAgree = (value) => {
    setIsAgree(value);
  };

  const setTheCurrentPage = (value) => {
    setCurrentPage(value);
  };

  const setDisplayName = (value) => {
    setTheDisplayName(value);
  };

  const setHasSetIsTyping = (value) => {
    setTheHasSetIsTyping(value);
  };

  const setUniversity = (value) => {
    setTheUniversity(value);
  };

  const setHasSuggested = (value) => {
    setTheHasSuggested(value);
  };

  const setCreatedChatroom = (value) => {
    setTheCreatedChatroom(value);
  };

  const setJoinedChatRoomID = (value) => {
    setTheJoinedChatRoomID(value);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        // auth states
        anonymousSignIn,
        logOut,
        user,
        // use states
        hasSetIsTyping,
        isOver18,
        isAgree,
        currentPage,
        displayName,
        university,
        hasSuggested,
        createdChatroom,
        joinedChatRoomID,
        // set states
        setHasSetIsTyping,
        setOver18,
        setAgree,
        setTheCurrentPage,
        setDisplayName,
        setUniversity,
        setHasSuggested,
        setCreatedChatroom,
        setJoinedChatRoomID,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
