import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import { UserAuth } from "../context/AuthContext";
import Intro from "./Intro";
import MainMenu from "./MainMenu";
import ChatSession from "./ChatSession";
import Selection from "./Selection";
import ChatRooms from "./ChatRooms";
import ChatRoomSession from "./ChatRoomSession";
import DailyThoughts from "./DailyThoughts";

const Home = () => {
  const {
    isOver18,
    isAgree,
    setOver18,
    setAgree,
    currentPage,
    setTheCurrentPage,
  } = UserAuth();

  if (currentPage === "Home") {
    return <Intro />;
  } else if (currentPage === "MainMenu") {
    return <MainMenu />;
  } else if (currentPage === "Selection") {
    return <Selection />;
  } else if (currentPage === "ChatRooms") {
    return <ChatRooms />;
  } else if (currentPage === "ChatSession") {
    return <ChatSession />;
  } else if (currentPage === "ChatRoomSession") {
    return <ChatRoomSession />;
  } else if (currentPage === "DailyThoughts") {
    return <DailyThoughts />;
  }
};

export default Home;
