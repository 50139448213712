import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import {
  FaCircle,
  FaArrowLeft,
  FaRegHeart,
  FaHeart,
  FaRegComment,
  FaUserCircle,
} from "react-icons/fa";
import { IoIosHeartEmpty } from "react-icons/io";
import { CiChat1 } from "react-icons/ci";
import { IoChatboxOutline, IoHeartOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { BsHeart } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import GoBack from "../components/GoBack";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp as firestoreTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  limit,
  setLogLevel,
  orderBy,
  startAfter,
  increment,
} from "firebase/firestore";
import ReactLoading from "react-loading";
import Navbar from "../components/Navbar";

const DailyThoughts = () => {
  const { user, displayName, university, setTheCurrentPage } = UserAuth();
  const [isMobile, setIsMobile] = useState(false);
  const [postTitle, setPostTitle] = useState("");
  const [postText, setPostText] = useState("");
  const [postDATA, setPostDATA] = useState([]);
  const [openPostSomething, setOpenPostSomething] = useState(false);
  const [isUploadingPost, setIsUploadingPost] = useState(false);

  const dtContainerRef = useRef(null);
  const textInputRef = useRef(null);
  const postTitleRef = useRef(null);
  const postMessageRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  const EachThought = ({ user, post, heartQty, commentQty }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const contentRef = useRef(null);

    const maxLines = 6; // Number of lines to show before truncating

    const handleToggleExpand = () => {
      setIsExpanded((prev) => !prev);
    };

    useEffect(() => {
      // Check if the text is truncated
      const checkIfTruncated = () => {
        if (contentRef.current) {
          const element = contentRef.current;
          setIsTruncated(element.scrollHeight > element.clientHeight);
        }
      };

      checkIfTruncated();
      // Re-check on resize or content change
      window.addEventListener("resize", checkIfTruncated);
      return () => window.removeEventListener("resize", checkIfTruncated);
    }, [post, isExpanded]);

    return (
      <div style={{ marginBottom: 25 }}>
        {/* Upper section: contains the user's name and university */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            {user.university === "I prefer not to disclose" ? (
              <FaUserCircle size={30} style={{ marginRight: 5 }} />
            ) : (
              <img
                style={{ height: 30, marginRight: 5 }}
                src={require(`../assets/universities/${user.university}.png`)}
                alt={`${user.university}`}
              />
            )}
            <p
              style={{
                margin: 0,
                fontSize: 12,
                fontWeight: "bold",
                userSelect: "none",
              }}
            >
              {user.displayName}
            </p>
          </div>
          <p
            style={{
              margin: 0,
              marginLeft: 5,
              fontSize: 12,
              color: "gray",
            }}
          >
            {/* For example, 5 hours ago - Implement a proper date/time format */}
            5h ago
          </p>
        </div>

        {/* Middle section: contains the post */}
        <div>
          <p
            style={{
              fontSize: 17,
              fontWeight: "bold",
              margin: 0,
              marginTop: 10,
              color: "#004AAD",
              userSelect: "none",
            }}
          >
            {post.title}
          </p>
          <p
            ref={contentRef}
            style={{
              color: "#4a4a4a",
              fontSize: 14,
              marginBottom: 0,
              marginTop: 7,
              whiteSpace: "pre-wrap",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: isExpanded ? "unset" : maxLines,
              cursor: "pointer",
              fontFamily: "sans-serif",
              userSelect: "none",
            }}
            onClick={handleToggleExpand}
          >
            {post.text}
          </p>
          {!isExpanded && isTruncated && (
            <p
              style={{
                // color: "#004AAD",
                color: "black",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: 12,
                marginTop: 5,
                textAlign: "center",
                userSelect: "none",
              }}
              onClick={handleToggleExpand}
            >
              Show more
            </p>
          )}
        </div>

        {/* Lower section: contains the number of likes and comments */}
        <div style={{ marginTop: 5, display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IoHeartOutline size={20} color={"black"} />
            <p
              style={{
                margin: 0,
                fontSize: 13,
                fontWeight: "bold",
                marginLeft: 2,
                color: "black",
              }}
            >
              {heartQty}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <IoChatboxOutline size={20} color={"black"} />
            <p
              style={{
                margin: 0,
                fontSize: 13,
                fontWeight: "bold",
                marginLeft: 2,
                color: "black",
              }}
            >
              {commentQty}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const PostSomething = () => {
    return (
      <div
        style={{ marginBottom: 5, cursor: "pointer" }}
        onClick={() => {
          setOpenPostSomething(true);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          {console.log(university)}
          {university === "I prefer not to disclose" ? (
            <FaUserCircle size={30} style={{ marginRight: 5 }} />
          ) : (
            <img
              style={{ height: 30, marginRight: 5 }}
              src={require(`../assets/universities/${university}.png`)}
              // alt={message.senderUniversity}
            />
          )}

          <label
            style={{
              fontSize: 13,
              margin: 0,
              width: "100%",
              // fontWeight: "",
              border: "1px solid #cfcfcf",
              backgroundColor: "white",
              color: "gray",
              padding: "10px",
              borderRadius: 20,
              userSelect: "none", // This line prevents text selection
            }}
          >
            Got something to say?
          </label>
        </div>
      </div>
    );
  };

  const BorderBottom = () => {
    return (
      <div
        style={{
          borderBottom: "2px solid #ccc", // Adjust color and thickness as needed
          margin: "20px 0", // Adds vertical space (gap) between the components
        }}
      />
    );
  };

  const LoadingScreen = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          opacity: 0.7,
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type="spin" color="#FF66C4" height={40} width={40} />
      </div>
    );
  };

  async function uploadPost() {
    setIsUploadingPost(true);
    const postsCollection = collection(db, "Posts");
    const postRef = await addDoc(postsCollection, {
      user: {
        userID: user.uid,
        displayName: displayName,
        university: university,
      },
      post: { title: postTitle, text: postText },
      timestamp: firestoreTimestamp(),
      heartQty: 0,
      commentQty: 0,
    });
    setIsUploadingPost(false);
    setOpenPostSomething(false);
  }

  useEffect(() => {
    // Automatically focus the textarea when the component mounts
    if (openPostSomething) {
      if (postTitleRef.current) {
        postTitleRef.current.focus();
      }
    }
  }, [openPostSomething]);

  useEffect(() => {
    const fetchPosts = async () => {
      const postsCollection = collection(db, "Posts");
      const postsQuery = query(postsCollection, orderBy("timestamp", "desc"));
      const querySnapshot = await getDocs(postsQuery);
      const posts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPostDATA(posts);
    };

    fetchPosts();
  }, []);

  return (
    <>
      {isUploadingPost ? <LoadingScreen /> : null}
      {isMobile ? (
        // mobile
        <div
          style={{
            padding: 20,
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              maxWidth: 800,
            }}
          >
            <Navbar nextPage={"Selection"} />
            {openPostSomething ? (
              <div
                style={{
                  paddingTop: 30,
                  display: "flex",
                  flexDirection: "column",
                  height: "90vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{ display: "flex", flex: 1 }}
                    onClick={() => {
                      setOpenPostSomething(false);
                    }}
                  >
                    <IoMdClose size={30} />
                  </div>

                  <button
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      border: "none",
                      background: "none",
                    }}
                    onClick={() => {
                      uploadPost();
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        padding: 10,
                        backgroundColor: "#ff66c4",
                        color: "white",
                        border: "none",
                        borderRadius: 10,
                        padding: "10px",
                        fontSize: 15,
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      Share
                    </p>
                  </button>
                </div>
                <input
                  ref={postTitleRef}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setPostTitle(inputValue);
                  }}
                  style={{
                    marginTop: 10,
                    border: "none",
                    fontSize: 17,
                    fontWeight: "bold",
                    outline: "none",
                    color: "#004AAD",
                  }}
                  placeholder="Title"
                />
                <textarea
                  maxLength={3000}
                  ref={postMessageRef}
                  placeholder="Say something about it"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setPostText(inputValue);
                  }}
                  value={postText}
                  style={{
                    minHeight: 500,
                    fontSize: 14,
                    paddingBottom: 10,
                    fontFamily: "inherit",
                    fontWeight: "inherit",
                    lineHeight: "inherit",
                    border: "none",
                    outline: "none",
                    overFlowY: "auto", // Hide the scrollbar
                    // resize: "none", // Disable manual resizing
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  marginTop: 40,
                }}
              >
                {/* mobile */}
                <PostSomething />
                <div style={{ marginBottom: 20 }}>
                  <p style={{ fontSize: 10, color: "gray" }}>
                    Please be mindful of what you post here. While your private
                    chats are protected by end-to-end encryption, the content
                    you share here is visible to everyone and monitored for
                    adherence to our community guidelines. Let's keep this space
                    positive and respectful!
                  </p>
                </div>
                {/* <BorderBottom /> */}
                {postDATA.map((post) => (
                  <EachThought
                    key={post.id}
                    user={post.user} // Passing the entire user object
                    post={post.post}
                    heartQty={post.heartQty}
                    commentQty={post.commentQty}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        //   desktop/laptop
        <div
          style={{
            display: "flex",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              width: "35%",
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              justifyContent: "space-between",
              boxShadow:
                "5px 0 15px rgba(0, 0, 0, 0.1), -5px 0 15px rgba(0, 0, 0, 0.1)", // Shadows on left and right sides
            }}
          >
            <div
              style={{
                flex: 1,
                overflowY: "scroll",
                paddingTop: "20px",
                paddingRight: "10px",
                paddingLeft: "10px",
              }}
            >
              <Navbar nextPage={"Selection"} />
              <div style={{ marginTop: 50 }}>
                {/* desktop */}
                <PostSomething />
                <div>
                  <p style={{ fontSize: 12, color: "gray" }}>
                    Please be mindful of what you post here. While your private
                    chats are protected by end-to-end encryption, the content
                    you share here is visible to everyone and monitored for
                    adherence to our community guidelines. Let's keep this space
                    positive and respectful!
                  </p>
                </div>
                <BorderBottom />
                {postDATA.map((post) => (
                  <EachThought
                    key={post.id}
                    university={post.user.university}
                    name={post.user.displayName}
                    post={post.post}
                    heartQty={post.heartQty}
                    commentQty={post.commentQty}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DailyThoughts;
