import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db, rtdb } from "../firebase";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { ref, set } from "firebase/database";
import Select from "react-select";
import ReactLoading from "react-loading";
import GoBack from "../components/GoBack";
import Navbar from "../components/Navbar";

const Selection = () => {
  const {
    anonymousSignIn,
    user,
    displayName,
    hasSetIsTyping,
    university,
    hasSuggested,
    setTheCurrentPage,
    setDisplayName,
    setUniversity,
    setHasSetIsTyping,
    setHasSuggested,
  } = UserAuth();

  return (
    <div className="select-rooms">
      <Navbar nextPage="MainMenu" />

      {/* DAILY THOUGHTS */}
      {/* <div style={{ maxWidth: 350, marginBottom: 40, marginTop: 50 }}>
        <p
          style={{
            fontWeight: "bold",
            marginBottom: 0,
            color: "#ff66c4",
            fontSize: 13,
          }}
        >
          NEW!
        </p>
        <p
          style={{
            color: "#000000",
            fontSize: 20,
            marginBottom: 0,
            marginTop: 0,
          }}
        >
          <strong>Daily thoughts</strong>
        </p>
        <p style={{ fontSize: 13, marginTop: 10 }}>
          Share a piece of your day! Whether it's an idea, story, question, or
          just what's on your mind, express it here and see what others have
          shared too!
        </p>
        <div style={{ display: "flex", marginTop: 10, maxWidth: 350 }}>
          <button
            onClick={() => {
              setTheCurrentPage("DailyThoughts");
            }}
            disabled={!user}
            style={{
              flex: 1,
              backgroundColor: user ? "#ff66c4" : "#ccc",
              color: "white",
              border: "none",
              borderRadius: "5px",
              height: "40px",
              cursor: user ? "pointer" : "not-allowed",
            }}
          >
            <h4 style={{ margin: 0, padding: 0 }}>Share</h4>
          </button>
        </div>
      </div> */}

      {/* CHAT WITH A STRANGER */}
      <div style={{ maxWidth: 350, marginBottom: 40, marginTop: 50 }}>
        <p style={{ color: "#000000", fontSize: 20, marginBottom: 0 }}>
          <strong>Chat with a Stranger</strong>
        </p>
        <div>
          <p style={{ fontSize: 13, marginTop: 10 }}>
            Connect anonymously with fellow college students across the
            Philippines. Share experiences and support each other while keeping
            your identity private for a safe and enriching chat experience!
          </p>
        </div>
        <div style={{ display: "flex", marginTop: 10, maxWidth: 350 }}>
          <button
            onClick={() => {
              setTheCurrentPage("ChatSession");
            }}
            disabled={!user}
            style={{
              flex: 1,
              backgroundColor: user ? "#ff66c4" : "#ccc",
              color: "white",
              border: "none",
              borderRadius: "5px",
              height: "40px",
              cursor: user ? "pointer" : "not-allowed",
            }}
          >
            <h4 style={{ margin: 0, padding: 0 }}>Connect</h4>
          </button>
        </div>
      </div>

      {/* JOIN CHATROOMS */}
      {/* <div style={{ marginBottom: 40, maxWidth: 350 }}>
        <p
          style={{
            color: "#000000",
            fontSize: 20,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <strong>Join Chatrooms</strong>
        </p>
        <div>
          <p style={{ fontSize: 13, marginTop: 10 }}>
            Discover and join chatrooms created by fellow students on topics
            that matter to you. Share your thoughts and connect with others in
            real-time!
          </p>
        </div>
        <div style={{ display: "flex", marginTop: 10 }}>
          <button
            disabled={true}
            onClick={() => {}}
            style={{
              flex: 1,
              backgroundColor: "#ccc",
              color: "white",
              border: "none",
              borderRadius: "5px",
              height: "40px",
              cursor: "not-allowed",
            }}
          >
            <h4 style={{ margin: 0, padding: 0 }}>Coming Soon</h4>
          </button>
          <button
            disabled={!user}
            onClick={() => {
              setTheCurrentPage("ChatRooms");
            }}
            style={{
              flex: 1,
              backgroundColor: user ? "#ff66c4" : "#ccc",
              color: "white",
              border: "none",
              borderRadius: "5px",
              height: "40px",
              cursor: user ? "pointer" : "not-allowed",
            }}
          >
            <h4 style={{ margin: 0, padding: 0 }}>Go to Chatrooms</h4>
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Selection;
